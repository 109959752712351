@import 'src/styles/variables.scss';

.data-processing-page {
    background-color: $white;
    height: 100%;
    flex: 1 0 auto;
    text-align: center;

    .data-processing-page-content {
        max-width: 550px;
        margin: 0 auto;
        padding-top: 40px;
    }

    .data-processing-page-texts {
        padding: 32px 32px 40px;
    }

    .data-processing-precision-text {
        margin: 16px 0 32px;
    }

    .contact-text {
        margin-top: 32px;
    }

    .meeting-date-box {
        background-color: $background-color;
        font-family: $font-SourceSansPro-semibold;
        font-size: 16px;
        line-height: 32px;
        padding: 6px 30px;
        width: fit-content;
        margin: 24px auto;
    }

    .calendly-link {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 16px;
        text-decoration: underline;
        cursor: pointer;
    }

    .data-processing-loader {
        display: flex;
        padding: 20px;
        justify-content: center;
    }
}
