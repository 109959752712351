// Purple
$purple-black: #1e1631;
$purple-100: #302745;
$purple-90: #443c57;
$purple-80: #59526a;
$purple-60: #837d8f;
$purple-20: #b9b6c2;
$purple-light: #e3e1e6;
$purple-bright: #f1f0f2;

// Blue
$blue-100: #5c95a5;
$blue-50: #89bac7;
$blue-20: #bddfe8;
$blue-bright: #e4f2f5;

// Red
$red-100: #dc7b7e;
$red-60: #d2979e;
$red-50: #ee9fa2;
$red-40: #ffb7b9;
$red-20: #f3bdbf;
$red-bright: #fae4e5;
$red-dark: #f02;

// Green
$green-100: #5aa27f;
$green-50: #82c2a2;
$green-20: #afe5ca;
$green-bright: #dff4e9;

// Gold
$gold-100: #eab76d;
$gold-50: #fdcf8b;
$gold-20: #ffddab;
$gold-bright: #fff1dd;

// Grey
$grey-100: #cac2bb;
$grey-50: #e8e2df;
$grey-20: #f1eeec;

// White
$white: #ffffff;
