@import 'src/styles/variables.scss';

.text-input-wrapper {
    margin-bottom: 24px;

    &.disabled {
        opacity: 0.6;

        input {
            background-color: $purple-bright;
        }
    }

    &.has-error {
        input,
        input:focus {
            border-color: $red-100;
        }
    }

    .text-label-wrapper {
        display: flex;
        justify-content: space-between;
    }

    .text-input-label {
        color: $text-secondary;
        font-family: $font-SourceSansPro-semibold;
        letter-spacing: 0.4px;
        text-transform: uppercase;
    }

    .requirement-text {
        color: $text-secondary;
    }

    .input-container {
        position: relative;
    }

    input,
    textarea {
        height: 48px;
        width: 100%;
        border-radius: 0;
        border: 1px solid $purple-20;
        margin-top: 2px;
        padding: 10px 10px 11px;
        color: $text-primary;
        font-family: $font-SourceSansPro-regular;
        font-size: 18px;
        line-height: 27px;

        &:focus {
            border-color: $button-primary;
        }

        &::placeholder {
            color: $disabled;
        }

        &[type='password'] {
            padding-right: 40px;
        }
    }

    textarea {
        height: 145px;
    }

    .password-icon {
        height: 20px;
        width: 20px;
        position: absolute;
        right: 10px;
        top: -1px;
        background-repeat: no-repeat;
        background-position: center;
        cursor: pointer;

        &.icon-on {
            background-image: url('../../../assets/icons/eye_icon_on.svg');
        }

        &.icon-off {
            background-image: url('../../../assets/icons/eye_icon_off.svg');
        }
    }

    .error-message {
        color: $red-100;
        text-align: left;
    }
}
