@import 'src/styles/variables.scss';

.cookie-banner-container {
    background-color: $background-overlay;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 24px 32px;
    position: fixed;
    width: 100%;
    z-index: 2147483005;

    @include desktop() {
        padding: 40px 32px;
    }
}

.cookie-banner-text {
    color: $white;

    @include desktop() {
        font-size: 16px;
        line-height: 24px;
    }
}

.cookie-banner-accept-button {
    background-color: $white;
    border: 0;
    color: $text-primary;
    font-size: 16px;
    line-height: 24px;
    padding: 5px 12px;
    margin-left: 24px;
    cursor: pointer;

    &:hover {
        background-color: $disabled;
    }

    @include desktop() {
        padding: 8px 12px;
        margin-left: 32px;
    }
}
