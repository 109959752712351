@import 'src/styles/variables.scss';

.button-primary {
    width: 100%;
    background-color: $button-primary;
    border: 0;
    padding: 10px 16px;
    margin: 8px 0;
    cursor: pointer;

    &.button-small {
        width: auto;
        padding: 8px 12px;
    }

    @media (hover: hover) {
        &:hover {
            background-color: $button-hover;

            .button-text {
                color: $disabled;
            }
        }
    }

    &:active {
        background-color: $button-hover;

        .button-text {
            color: $disabled;
        }
    }

    &:disabled {
        background-color: $disabled;
        pointer-events: none;
    }

    .button-text {
        color: $white;
        font-family: $font-SourceSansPro-semibold;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.button-basic,
.button-standard,
.button-advanced {
    @media (hover: hover) {
        &:hover {
            .button-text {
                color: $white;
            }
        }
    }

    &:active {
        .button-text {
            color: $white;
            opacity: 0.7;
        }
    }
}

.button-basic {
    background: linear-gradient(
        -90deg,
        rgb(147, 108, 125) 0%,
        rgb(48, 39, 69) 100%
    );

    @media (hover: hover) {
        &:hover {
            background: #302745;
        }
    }

    &:active {
        background: #1e1631;
    }
}

.button-standard {
    background: linear-gradient(
        -90deg,
        rgb(74, 218, 195) 0%,
        rgb(33, 156, 189) 100%
    );

    @media (hover: hover) {
        &:hover {
            background: #33b8c0;
        }
    }

    &:active {
        background: #229dbd;
    }
}

.button-advanced {
    background: linear-gradient(
        -90deg,
        rgb(249, 194, 113) 0%,
        rgb(255, 115, 118) 100%
    );

    @media (hover: hover) {
        &:hover {
            background: #fc9374;
        }
    }

    &:active {
        background: #fe7576;
    }
}
