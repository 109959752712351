.payment {
    margin: 0 auto;
    margin-top: 65px;
    &-text {
        margin-top: 23px;
        margin-bottom: 68px;
        color: rgb(48, 39, 69);
        font-family: 'Source Sans Pro SemiBold';
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        text-align: center;
    }
    svg {
        margin: 0 auto;
        display: block;
    }
}