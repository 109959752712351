html,
body {
    height: 100%;
}

#root {
    height: 100%;
}

.root-content,
.careers-content {
    min-height: 100%;
    display: flex;
    flex-direction: column;
}

main {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
}

footer {
    flex-shrink: 0;
}
