@import 'src/styles/variables.scss';

.authentication-page-main {
    .authentication-content-wrapper {
        display: flex;
        flex-direction: column;
        background-color: $white;
        height: 100%;
        width: 100%;
        flex: 1 0 auto;

        &:not(.portrait-share-page-wrapper) {
            padding: 16px 32px 40px;
        }
        .login-form-verification {
            background: #c7f8df;
            color: #216d47;
            text-align: center;
            padding: 10px 16px;
            margin-bottom: 24px;
        }
    }

    .page-content {
        flex: 1;
        width: 100%;
    }

    .authentication-form {
        max-width: 440px;
        margin: 0 auto;
    }

    .page-title {
        margin-bottom: 32px;
        text-align: center;
    }

    .contact-text {
        border-top: 1px solid $purple-light;
        color: $text-secondary;
        padding-top: 32px;
        margin-top: 40px;
        text-align: center;
    }

    .terms-accept-text {
        text-align: center;
        margin-top: 24px;
    }

    .forgot-password-link {
        display: block;
        margin: 12px auto 0;
        max-width: 440px;
    }

    .success-page {
        display: flex;
        flex-direction: column;
        align-items: center;

        .success-text {
            margin: 24px 0;
            max-width: 312px;
            text-align: center;
        }

        svg {
            @include desktop() {
                width: 112px;
                height: 112px;
            }
        }
    }

    .policy-page {
        text-align: justify;

        h3 {
            margin: 24px 0 12px;
        }

        p {
            margin: 16px 0;
        }

        h3 + p {
            margin-top: 12px;
        }

        ul {
            margin-left: 16px;
        }

        li {
            margin-bottom: 12px;
        }

        b {
            // font-family: $font-SourceSansPro-semibold;
        }
    }
}
