@import 'src/styles/variables.scss';

footer {
    padding: 32px 32px 24px;

    @include desktop() {
        display: flex;
        flex-direction: row-reverse;
        align-items: flex-end;
    }

    &.footer-landing {
        padding: 24px 0;
    }

    .footer-links {
        flex: 1 0 auto;

        a {
            text-decoration: none;
        }
    }

    .links-column {
        display: flex;
        flex-direction: column;

        &.first-column {
            min-width: 33%;
            max-width: 50%;
        }

        &.second-column {
            margin-left: 32px;
        }

        > a {
            line-height: 28px;
        }
    }

    .social-links-copyright {
        margin-top: 24px;
    }

    .social-links {
        display: flex;
        margin-bottom: 10px;
    }

    .social-link {
        display: block;
        margin-right: 10px;
    }

    .copyright {
        a,
        span {
            color: $purple-60;
            margin-right: 16px;
        }
    }

    .logo-wrapper {
        line-height: 0;
        margin-bottom: 4px;
        float: right;
    }
}
