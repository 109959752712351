@import 'src/styles/variables.scss';

.portrait-page {
    &-button {
        width: 100%;
        padding-right: 24px;
        display: flex;
        justify-content: flex-end;
        background: #fff;

        & .button-primary {
            width: auto;
        }
    }

    &-pattern-title {
        font-family: 'Source Sans Pro Bold';
        font-size: 18px;
        text-transform: uppercase;
        font-weight: bold;
        margin-top: 24px;

        display: flex;

        align-items: center;
    }

    &-pattern-icon {
        width: 20px;
        height: 20px;
        fill: currentColor;
        color: rgba(30, 22, 49, 0.31);
        margin-left: 6px;
        flex-shrink: 0;
    }

    .tabs-small {
        margin-top: 24px;
    }

    .portrait-tabs {
        background: #fff;
        padding: 0 20px;
        height: 40px;
        display: flex;
        align-items: center;
        overflow: auto;
        box-shadow: 0px 1px 4px 0px rgba(30, 22, 49, 0.07), 6px 6px 10px 0px rgba(0, 0, 0, 0.03);

        position: sticky;
        top: 0px;

        z-index: 1;

        scrollbar-width: none;

        &::-webkit-scrollbar {
            display: none;
        }

        &-btn {
            font-size: 18px;
            color: $purple-80;
            text-decoration: none;
            padding: 0;
            height: inherit;
            padding-top: 10px;

            &:hover {
                cursor: pointer;
            }

            &:not(:last-child) {
                margin-right: 20px;
            }

            &.active {
                font-family: 'Source Sans Pro SemiBold';
                font-weight: 600;
                color: $purple-100;
                border-bottom: 2px solid $purple-100;
            }

            &.disabled {
                opacity: 0.4;
            }

            &.hidden {
                display: none;
            }
        }

        @include desktop {
            justify-content: center;
        }
    }

    .pattern-accordion {
        margin-top: 40px;

        @include desktop {
            max-width: calc(100% - 200px);
            margin-left: auto;
            margin-right: auto;
        }

        &-item {
            border-top: 1px solid rgba(202, 194, 187, 0.5);
            padding-bottom: 24px;

            &:last-child {
                border-bottom: 1px solid rgba(202, 194, 187, 0.5);
            }
        }

        &-title {
            font-family: 'Source Sans Pro SemiBold';
            font-size: 18px;
            font-weight: 600;
            cursor: pointer;
            padding: 12px 12px 0 0;
            user-select: none;

            display: flex;
            justify-content: space-between;
            align-items: center;

            &-icon {
                width: 8px;
                height: 5px;
                flex-shrink: 0;

                g {
                    fill: currentColor;
                }
            }

            &.opened + .pattern-accordion-content {
                display: block;
            }

            &.opened .pattern-accordion-title-icon {
                transform: rotate(180deg);
            }
        }

        &-content {
            display: none;
            padding-bottom: 12px;
            line-height: 1.5;
            font-size: 16px;
            margin-left: 20px;
            margin-top: 16px;
            &-list {
                li::before {
                    background-image: url('../../../assets/icons/red_bullet.svg');
                }
            }
            h4 {
                margin: 24px 0 16px -18px;
            }
            .react-markdown-paragraph {
                margin: 24px 0 16px -18px;
                white-space: pre-line;
                strong {
                    text-transform: uppercase;
                }
            }
        }

        &-item.is-disabled {
            .pattern-accordion-title {
                opacity: 0.4;
            }

            .pattern-accordion-title-icon {
                width: 12px;
                height: 16px;
            }
        }
    }

    .content-block-new-wrapper {
        .pattern-title {
            padding: 36px 20px 0 32px;
            font-size: 20px;
            font-weight: 600;
            line-height: 28px;
            text-transform: uppercase;
            font-family: 'TT Hoves DemiBold';
            @include desktop {
                padding-left: 70px;
            }
        }
        .content-block-new {
            background: $white;
            padding: 24px 0 40px;
            @include desktop {
                padding: 24px 0 40px;
            }
            &:nth-child(2n) {
                background: #f6f1ee;
            }

            &-pattern {
                font-family: 'Source Sans Pro SemiBold';
                text-transform: uppercase;
                font-size: 18px;
                font-weight: bold;
                line-height: 1;
                display: flex;
                align-items: center;
                &-image {
                    margin: 0 auto 32px auto;
                    display: block;
                    width: 215px;
                }
                &-title-icon {
                    width: 32px;
                    height: 33px;
                    margin-right: 8px;
                    display: flex;
                }
                &-icon {
                    width: 20px;
                    height: 20px;
                    fill: currentColor;
                    color: rgba(30, 22, 49, 0.31);
                    margin-left: 6px;
                    flex-shrink: 0;
                }

                @include desktop {
                    font-size: 20px;
                    max-width: calc(100% - 200px);
                    padding: 0 70px;
                }
            }

            &-pattern-type {
                font-family: 'Source Sans Pro SemiBold';
                margin: 24px 0 16px 0;
                font-size: 24px;
                font-weight: 600;
                height: 32px;
                letter-spacing: -0.4px;
                line-height: 32px;
                margin: 0 32px;

                @include desktop {
                    font-size: 40px;
                    margin: 0 70px;
                    margin-top: 40px;
                    max-width: calc(100% - 200px);

                }
            }

            &-pattern-desc {
                font-family: 'Source Sans Pro Regular';
                font-weight: normal;
                font-size: 16px;
                line-height: 24px;
                white-space: pre-line;
                position: relative;
                margin: 24px 32px 32px 32px;

                .pattern-link {
                    color: rgb(48, 39, 69);
                    font-size: 16px;
                    margin-top: 16px;
                    font-weight: normal;
                    line-height: 22px;
                    cursor: pointer;
                    text-decoration: underline;
                    svg {
                        margin-right: 7px;
                        margin-bottom: -2px;
                    }
                }
                .toggle-text {
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    display: block;
                    &-fade {
                        position: absolute;
                        bottom: 0;
                        right: 47px;
                        width: 4em;
                        height: 20px;
                        background: linear-gradient(to right, transparent, white);
                    }
                }

                @include desktop {
                    font-size: 18px;
                    margin: 24px 70px 32px 70px;
                }
            }
        }
    }

    .hero-block {
        display: flex;
        flex-direction: column;
        padding: 24px 20px 40px;
        align-items: center;
        position: relative;

        @include desktop() {
            padding: 32px 32px 40px;
        }

        &-anchor {
            position: relative;
            top: -40px;
            visibility: hidden;
        }
    }

    .user-profile {
        height: 106px;
        width: 106px;
        margin: 32px 0 8px;

        @include desktop() {
            margin-top: 44px;
        }
    }

    .user-initials {
        font-size: 40px;
        line-height: 48px;
    }

    .tab-explanation {
        margin-top: 16px;
        max-width: 300px;
        text-align: center;

        .explanation-heading {
            margin-bottom: 8px;
        }

        .explanation-bold {
            // font-family: $font-SourceSansPro-semibold;
        }
    }

    .role-values-labels {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        margin-top: 24px;
        max-width: 300px;

        @include desktop() {
            max-width: initial;
        }
    }

    .share-profile {
        position: absolute;
        top: 30px;
        right: 30px;


        @include desktop() {
            top: 40px;
            right: 40px;
        }
    }

    .share-profile-content {
        position: relative;
    }

    .share-profile-text {
        display: flex;
        align-items: center;
        justify-content: center;
        width: max-content;
        margin: 0 auto;
        cursor: pointer;

        svg {
            margin-right: 8px;
        }

        &.has-opacity {
            opacity: 0.6;
        }
    }

    .share-profile-popup {
        position: absolute;
        width: 335px;
        background-color: $white;
        box-shadow: 6px 6px 10px 0px rgba(30, 22, 49, 0.2);
        padding: 20px 20px 28px;

        @include media(max-width, 375px) {
            width: 300px;
        }

        top: 30px;
        right: -20px;
        padding-bottom: 20px;

        > svg {
            position: absolute;
            top: 12px;
            right: 12px;
            cursor: pointer;
        }

        .popup-triangle {
            width: 0;
            height: 0;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            position: absolute;
        }

        .triangle-up {
                display: block;
                border-bottom: 10px solid $white;
                top: -9px;
                right: 55px;
        }

        .triangle-down {
            border-top: 10px solid $white;
            bottom: -9px;
            left: 50%;
            transform: translateX(-50%);
            display: none;

            @include desktop() {
                display: none;
            }
        }

        .button-primary {
            position: relative;
            margin: 16px 0;

            &::before {
                content: '';
                background-image: url('../../../assets/icons/share_link_icon.svg');
                background-size: cover;
                position: absolute;
                top: 14px;
                left: 12px;
                height: 18px;
                width: 18px;
            }
        }
    }

    .share-popup-title {
        // font-family: $font-SourceSansPro-semibold;
    }

    .share-revoke-text {
        color: $text-secondary;
    }

    .share-link-copied {
        position: relative;
        width: 100%;
        background-color: $green-bright;
        color: $green-100;
        // font-family: $font-SourceSansPro-semibold;
        line-height: 27px;
        text-align: center;
        padding: 10px 16px;
        margin: 16px 0;

        &::before {
            content: '';
            background-image: url('../../../assets/icons/share_copied_icon.svg');
            background-size: cover;
            position: absolute;
            top: 10px;
            left: 8px;
            height: 26px;
            width: 26px;
        }
    }

    .situation-do {
        // font-family: $font-SourceSansPro-bold;
        display: block;
    }

    .learn-more {
        margin-top: 16px;

        @include desktop() {
            margin-top: 24px;
        }
    }

    .limited-plan {
        padding: 20px;
        background-color: $white;
        text-align: center;
        margin-top: 32px;

        &__upgrade {
            text-decoration: underline;
            cursor: pointer;
        }
    }

    .upgrade-modal {
        text-align: center;

        &-title {
            font-size: 20px;
            font-weight: 600;
            line-height: 28px;
        }

        &-description {
            font-size: 16px;
            margin-top: 16px;
            margin-bottom: 32px;
        }
    }
}

