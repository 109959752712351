@import 'src/styles/variables.scss';

.invite-friends-modal {
    .invite-precision {
        color: $text-secondary;
        margin-bottom: 8px;
    }

    .invite-success-text {
        color: $green-100;
        margin-bottom: 16px;
        // font-family: $font-SourceSansPro-semibold;
    }

    .invite-icon {
        display: flex;
        justify-content: center;
        margin-bottom: 30px;
    }
    .invite-friends-title-block {
        text-align: center;
        margin-bottom: 30px;
    }
    .invite-friends-title {
        margin-bottom: 13px;
    }
}
