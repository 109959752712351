@import 'src/styles/variables.scss';

html,
body,
#root {
    box-sizing: border-box;
    background-color: $background-color;
    color: $text-primary;
    font-family: $font-SourceSansPro-regular;
    font-size: 14px;
    line-height: 21px;
    margin: 0;
    padding: 0;

    *,
    * :before,
    *:after {
        box-sizing: inherit;
    }
}

h1,
h2,
h3 {
    margin: 0;
}

h1 {
    font-family: $font-TThoves-bold;
    font-size: 40px;
    line-height: 48px;
}

h2 {
    font-family: $font-SourceSansPro-semibold;
    font-size: 24px;
    line-height: 31px;

    @include desktop() {
        font-size: 32px;
        line-height: 44px;
    }
}

h3 {
    font-family: $font-SourceSansPro-semibold;
    font-size: 20px;
    line-height: 28px;
}

button,
input,
select {
    font-family: $font-SourceSansPro-regular;
    -webkit-tap-highlight-color: transparent;

    &:focus {
        outline: none;
    }
}

input,
input[type='text'],
input[type='password'],
input[type='number'],
textarea,
select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

ul,
li {
    margin: 0;
    padding: 0;
}

li {
    &::marker {
        font-size: 12px;
    }
}

.list-item-image {
    list-style: none;
    line-height: 28px;
    padding-left: 28px;
    background-position: left center;
    background-repeat: no-repeat;

    @include desktop() {
        font-size: 16px;
    }

    // TODO: alpha
    &.alfa {
        background-image: url('../../assets/icons/bullet_icon_alpha.svg');
    }

    &.beta {
        background-image: url('../../assets/icons/bullet_icon_beta.svg');
    }

    &.gamma {
        background-image: url('../../assets/icons/bullet_icon_gamma.svg');
    }

    &.delta {
        background-image: url('../../assets/icons/bullet_icon_delta.svg');
    }
}

a,
.link {
    color: $link-primary;
    cursor: pointer;
    text-decoration: underline;

    &:visited {
        color: $link-primary;
    }

    &.white-link,
    &.white-link:visited {
        color: rgba(255, 255, 255, 0.7);
    }
}

p {
    margin: 0;
}

b {
    font-family: $font-SourceSansPro-semibold;
}

iframe {
    border: 0;
}
.text-xxl {
    font-size: 20px;
    line-height: 28px;
}

.text-xl {
    font-size: 18px;
    line-height: 27px;
}

.text-l {
    font-size: 16px;
    line-height: 24px;
}

.text-s {
    font-size: 12px;
    line-height: 18px;
}

.text-m {
    font-size: 14px;
    line-height: 21px;
}
.text-xs {
    font-size: 10px;
    line-height: 15px;
}

.root-content {
    width: 100%;
    max-width: $content-max-width;
    margin: 0 auto;
}

.icon-text-page {
    background-color: #ffffff;
    height: 100%;
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    .icon-text-page-text {
        margin: 24px 32px;
        max-width: 440px;
    }
}

.user-avatar {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 50%;
    height: 100%;
    width: 100%;
}

.user-initials {
    color: $white;
    font-family: $font-TThoves-regular;
    background-color: $purple-100;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
}

.modal-success-text {
    margin-bottom: 24px;
    text-align: left;
}

.guidelines-list {
    margin-left: 16px;
}
