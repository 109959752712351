@import 'src/styles/variables.scss';

.modal-window-background {
    width: 100%;
    height: 100%;
    background: $background-overlay;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 20;

    .modal-window {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: calc(100% - 16px);
        max-width: 640px;
        min-height: 200px;
        max-height: calc(100% - 40px);
        background-color: $white;
        display: flex;
        flex-direction: column;
        padding: 16px 24px 32px;
        overflow: auto;
    }

    .modal-heading-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 32px;
    }
}
