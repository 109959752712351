@import 'src/styles/variables';

.slick-slider {
    .slick-dots {
        position: relative;
        bottom: 0;
        margin-top: 10px;

        li {
            height: 16px;
            width: 16px;

            &.slick-active button::before {
                background-color: $purple-100;
            }

            button {
                &::before {
                    content: '';
                    height: 10px;
                    width: 10px;
                    background-color: rgba(48, 39, 69, 0.2);
                    border-radius: 50%;
                    opacity: 1;
                }

                &:hover::before {
                    background-color: $purple-100;
                }
            }
        }
    }

    .slick-arrow {
        height: 27px;
        width: 15px;

        @include desktop() {
            height: 48px;
            width: 24px;
        }

        &::before {
            content: '';
        }

        &.slick-prev {
            background: url('../../assets/icons/arrow_slim_mobile.svg');
            transform: rotate(180deg);

            @include desktop() {
                background: url('../../assets/icons/arrow_slim.svg');
            }
        }

        &.slick-next {
            background: url('../../assets/icons/arrow_slim_mobile.svg');

            @include desktop() {
                background: url('../../assets/icons/arrow_slim.svg');
            }
        }
    }
}
