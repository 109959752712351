@import 'src/styles/variables/breakpoints';

@mixin media($minOrMaxWidth, $breakpoint) {
    @media screen and ($minOrMaxWidth: $breakpoint) {
        @content;
    }
}

@mixin between($minWidth, $maxWidth) {
    @media screen and (min-width: $minWidth) and (max-width: $maxWidth) {
        @content;
    }
}

@mixin mobile() {
    @media screen and (max-width: $desktop-breakpoint) {
        @content;
    }
}

@mixin desktop() {
    @media screen and (min-width: $desktop-breakpoint) {
        @content;
    }
}

@mixin landingMobile() {
    @media screen and (max-width: $landing-desktop-breakpoint) {
        @content;
    }
}

@mixin landingDesktop() {
    @media screen and (min-width: $landing-desktop-breakpoint) {
        @content;
    }
}

@mixin laptop() {
    @media screen and (min-width: $laptop-breakpoint) {
        @content;
    }
}

@mixin fullScreen() {
    @media screen and (min-width: $fullscreen-breakpoint) {
        @content;
    }
}
