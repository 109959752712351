@import 'src/styles/variables.scss';

.upgrade-sticky {
    background-color: $button-primary;
    color: $white;
    position: sticky;
    bottom: 0;

    .upgrade-sticky-content-wrapper {
        max-width: $content-max-width;
        margin: 0 auto;
        position: relative;
    }

    .upgrade-sticky-collapse {
        position: absolute;
        top: -14px;
        right: 20px;
        cursor: pointer;

        @include media(min-width, $content-max-width) {
            right: 0;
        }

        &.collapsed {
            transform: rotate(180deg);
            top: -22px;
        }
    }

    .upgrade-sticky-content {
        max-width: 540px;
        margin: 0 auto;
        padding: 16px 20px 32px;

        @include desktop() {
            padding-top: 24px;
        }

        &.collapsed {
            padding: 12.5px 20px;

            > * {
                display: none;
            }
        }
    }

    .upgrade-sticky-text {
        @include desktop() {
            font-size: 16px;
            line-height: 20px;
            text-align: center;
        }
    }

    .upgrade-sticky-line-wrapper {
        display: flex;
        align-items: center;
        margin-top: 16px;

        @include desktop() {
            margin-top: 24px;
        }
    }

    .upgrade-line {
        position: relative;

        svg {
            max-width: 100%;
            margin-bottom: 16px;
        }
    }

    .upgrade-line-label {
        font-family: $font-SourceSansPro-semibold;
        font-size: 10px;
        text-transform: uppercase;
        position: absolute;
        bottom: 0;

        @include media(min-width, 375px) {
            font-size: 11px;
        }

        @include desktop() {
            font-family: $font-SourceSansPro-bold;
        }

        &.basic-label {
            left: 0;
        }

        &.standard-label {
            left: 20%;

            @include media(min-width, 375px) {
                left: 22%;
            }

            @include media(min-width, 460px) {
                left: 24%;
            }
        }

        &.advanced-label {
            right: 0;
        }
    }

    .upgrade-sticky-button {
        height: 27px;
        display: block;
        min-width: 70px;
        margin: 12px auto 0 auto;
        line-height: 21px;
        padding: 3px 11px;
        background: linear-gradient(-90deg, rgb(249, 194, 113) 0%, rgb(255, 115, 118) 100%);
        font-weight: 600;
        color: $text-primary;
        font-family: $font-SourceSansPro-semibold;
        border: 0;
        border-radius: 13.5px;
        cursor: pointer;
    }
}
