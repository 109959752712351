@import 'src/styles/variables.scss';

.interview-success-page {
    max-width: 440px;
    margin: 0 auto;

    p,
    img {
        margin-bottom: 16px;
    }

    img {
        max-width: 100%;
    }

    h3 {
        text-align: center;
        margin: 32px 0 24px;
    }
}
