@import 'src/styles/variables.scss';

header {
    .header-main {
        background-color: $white;
        min-height: 64px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 16px 20px;
    }

    .header-logo {
        display: flex;
        align-items: center;
        cursor: pointer;
    }
}
