// TT Hoves
@font-face {
    font-family: 'TT Hoves Bold';
    src: url('../../assets/fonts/TTHoves-Bold.woff') format('woff');
    src: url('../../assets/fonts/TTHoves-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'TT Hoves DemiBold';
    src: url('../../assets/fonts/TTHoves-DemiBold.woff') format('woff');
    src: url('../../assets/fonts/TTHoves-DemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'TT Hoves Medium';
    src: url('../../assets/fonts/TTHoves-Medium.woff') format('woff');
    src: url('../../assets/fonts/TTHoves-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'TT Hoves Regular';
    src: url('../../assets/fonts/TTHoves-Regular.woff') format('woff');
    src: url('../../assets/fonts/TTHoves-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'TT Hoves Light';
    src: url('../../assets/fonts/TTHoves-Light.woff') format('woff');
    src: url('../../assets/fonts/TTHoves-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

// Source Sans Pro
@font-face {
    font-family: 'Source Sans Pro Bold';
    src: url('../../assets/fonts/SourceSansPro-Bold.woff') format('woff');
    src: url('../../assets/fonts/SourceSansPro-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Source Sans Pro SemiBold';
    src: url('../../assets/fonts/SourceSansPro-SemiBold.woff') format('woff');
    src: url('../../assets/fonts/SourceSansPro-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Source Sans Pro Regular';
    src: url('../../assets/fonts/SourceSansPro-Regular.woff') format('woff');
    src: url('../../assets/fonts/SourceSansPro-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Source Sans Pro Light';
    src: url('../../assets/fonts/SourceSansPro-Light.woff') format('woff');
    src: url('../../assets/fonts/SourceSansPro-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

// Sharp Grotesk
@font-face {
    font-family: 'Sharp Grotesk Book';
    src: url('../../assets/fonts/SharpGroteskBook20.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Sharp Grotesk Medium';
    src: url('../../assets/fonts/SharpGroteskMedium22.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
