@import 'src/styles/variables.scss';

header {
    .header-main {
        min-height: 64px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 16px 20px;
    }

    .header-left {
        display: flex;

        @include desktop() {
            flex: 1;
            align-items: center;
            max-width: calc(100% - 32px);
        }
    }

    .header-logo {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    .header-username {
        height: 32px;
        width: 32px;
        cursor: pointer;
    }

    .user-initials {
        font-size: 16px;
        line-height: 24px;
    }

    .header-extension {
        background-color: $white;
        padding: 8px 20px 8px 0;
        display: flex;
        position: relative;

        @include desktop() {
            max-width: calc(100% - 108px);
            margin-left: 50px;
            padding-right: 40px;
        }
    }

    .connections {
        display: flex;
        width: 100%;

        @include desktop() {
            padding: 0;
        }
    }

    .connections-items {
        display: flex;
        align-items: center;
        overflow-x: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;
        padding-left: 20px;
        width: 100%;

        @include desktop() {
            padding: 0;
        }

        &::-webkit-scrollbar {
            display: none;
        }
    }

    .connection {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-right: 4px;

        &:first-of-type {
            margin-right: 6px;

            .connection-photo-wrapper {
                width: 52px;
            }
        }

        &:last-of-type:not(:only-of-type) {
            padding-right: 45px;
        }

        &:only-of-type {
            padding-right: 20px;
        }
    }

    .connection-photo-wrapper {
        height: 56px;
        width: 56px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        &.is-active {
            border-radius: 50%;
        }
    }

    .connection-photo {
        height: 52px;
        width: 52px;
        background-size: cover;
        border-radius: 50%;
        border: 2px solid $white;
    }

    .connection-name {
        font-size: 10px;
        line-height: 12px;
        text-align: center;
        width: 52px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        cursor: pointer;
    }

    .no-connections {
        flex: 1;
        color: $text-secondary;
        text-align: center;
        margin-right: 50px;
        padding-left: 20px;

        @include desktop() {
            margin-right: 200px;
        }
    }

    .connection-arrow {
        cursor: pointer;
        position: absolute;
        top: 0;
        height: 100%;
        display: flex;

        &.left-arrow {
            left: -30px;
        }

        &.right-arrow {
            right: 40px;

            .connection-arrow-icon {
                padding: 25px 20px 0 16px;
            }

            svg {
                transform: rotate(180deg);
            }
        }

        &.hidden {
            display: none;
        }

        .connection-arrow-icon {
            background: $white;
            height: 100%;
            padding-top: 25px;
        }
    }

    .plus-icon-wrapper {
        cursor: pointer;
        position: absolute;
        right: 20px;
        top: 0;
        height: 100%;
        display: flex;

        .plus-icon {
            background: $white;
            height: 100%;
            padding: 26px 0 0 8px;
        }
    }

    .gradient {
        background: linear-gradient(
            90deg,
            rgba(255, 255, 255, 0) 0%,
            rgb(255, 255, 255) 100%
        );
        height: 100%;
        width: 22px;
    }
}
